<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <a :href="absoluteUrl()" target="_blank" class="logo logo-dark">
              <span class="logo-sm">
                <img :src="absoluteUrl('/images/logos/logo-sm-dark.png')" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img :src="absoluteUrl('/images/logos/logo-dark.png')" alt="" height="27" />
              </span>
            </a>
            <a :href="absoluteUrl()" target="_blank" class="logo logo-light')">
              <span class="logo-sm">
                <img :src="absoluteUrl('/images/logos/logo-sm-light.png')" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img :src="absoluteUrl('/images/logos/logo-light.png')" alt="" height="27" />
              </span>
            </a>
          </div>

          <button
            type="button"
            class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            "
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- App Search-->
          <AppSearch />
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3" @submit.prevent>
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Search something"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="bx bx-search-alt-2"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <AppNotification />

          <AppProfile />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { SimpleBar } from "simplebar-vue3";
import AppSearch from "./AppSearch.vue";
import AppProfile from "./AppProfile.vue";
import AppNotification from "./AppNotification.vue";

/**
 * Nav-bar Component
 */
export default {
  components: { SimpleBar,  AppSearch, AppProfile, AppNotification },
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

  },
  methods: {
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
  computed: {},
};
</script>

