<template>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                ©{{new Date().getFullYear()}}  {{ $store.state.appName}}
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    {{$store.state.config.shortDesc}}
                </div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
    data(){
        return{
           
        }
    },
    mounted(){

    }
}
</script>